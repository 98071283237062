.profile-component {
  .password {
    height: 74px;

    .mat-mdc-form-field-infix {
      display: contents;
    }
  }


  .content-box {
    color: #525252;

    .title {
      color: #1F1718;
    }
  }

  .content-extra-info {
    justify-content: space-between;

    @media (max-width:550px) {
      flex-direction: column;
    }

    .location-detail-content,
    .closed-count {
      //width: 100%;
    }

    .location-detail-content {
      padding: 30px 35px;
      background: #f5f5f5;
      align-items: center;

      @media (max-width:550px) {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
      }

      .l-img {
        margin-right: 50px;

        img {
          height: 80px;
        }
      }

      .l-info {
        color: #1F1718;

        .l-title {
          font-size: 25px;
        }

        .l-subtitle {
          color: $primary;
          font-size: 18px;
        }
      }
    }

    .closed-count {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      @media (max-width:550px) {
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
      }

      .title {
        font-size: 18px;
        color: #1F1718;
        margin: 0;
        margin-right: 5px;
      }

      .close-acc-btn {
        font-size: 18px;
        background: none !important;
        border: none;
        padding: 0 !important;
        text-decoration: underline;
        cursor: pointer;
        color: $primary;
      }
    }
  }

  .profile-data {
    h3 {
      color: $primary;
    }

    .inner-content-box {
      display: flex;
      flex-direction: row;

      &>div {
        margin: 20px;
      }

      .profile-image {
        position: relative;
        width: 110px;
        height: 110px;
        margin-bottom: 10px;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        &.empty {
          background-image: url("/assets/img/no-results/user.svg");
        }

        .delete-picture {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          opacity: 0;
          visibility: hidden;
          background-color: rgba(0, 0, 0, 0.1);
          transition: all 0.2s ease;
        }

        &:hover {
          .delete-picture {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .profile-content {
    display: flex;
    width: 100%;
    margin-top: 16px;

    .container {
      width: 50%;

      .content-box {
        margin-bottom: 16px;
      }

      &.right {
        margin-left: 16px;
        text-align: center;
      }

      .close-acc-btn {
        margin-top: 20px;
      }
    }

    h3 {
      font-size: 20px;
      color: $primary;
    }
  }
}