.instrucciones {
  font-family: 'Roboto', sans-serif;
  color: #525252;
}

.instrucciones .center {
  text-align: center;
}

.instrucciones .image-center-block {
  display: block;
  margin: auto;
}

.instrucciones .three-column,
.instrucciones .two-column {
  display: flex;
  align-items: flex-start;
}

.instrucciones .two-column > div {
  flex: 1 1 auto;
}

.instrucciones .three-column > div {
  flex: 1 1 auto;
}

.instrucciones a {
  color: #a70532;
  text-decoration: underline;
}

.instrucciones .title {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.instrucciones .subtitle {
  text-transform: uppercase;
  text-decoration: underline;
}

.instrucciones .conditions {
  font-size: 12px;
  font-style: italic;
}

.instrucciones {
  ol,
  ul {
    margin: 0;
    margin-left: 20px;
  }
}

@media (max-width: 600px) {
  .instrucciones .three-column,
  .instrucciones .two-column {
    flex-direction: column;
    align-items: center;
  }
}
