.video-component,
.test-component {

  iframe {
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
  }

  .already-viewed {
    text-align: center;

    img {
      width: 100px;
    }
  }

  .video-container {
    position: relative;

    &:hover .video-control.pause {
      opacity: 1;
      visibility: visible;
    }

    .video-control {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0.3);
      border: 3px solid #ffffff;
      border-radius: 50%;
      transition: all 0.2s ease;

      &.pause {
        opacity: 0;
        visibility: hidden;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }

      .mat-icon {
        font-size: 65px;
        text-align: center;
        width: 100px;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
      }
    }
  }
}
