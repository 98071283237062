.footer-component {
  background-color: #ffffff;
  padding: 30px 0;

  .links-and-info {
    border-bottom: solid 1px #b9b9b9;
    text-align: center;
    padding: 0 20px 60px 20px;
    
    .inner {
      text-align: left;
      display: inline-block;
    }

    .box {
      vertical-align: top;
      text-align: left;
      margin: 20px 0 0 0;
      display: inline-block;
      width: 300px;

      h3 {
        font-weight: 600;
        color: $primary;
        font-size: 20px;
        margin: 0 0 15px 0;

        .dark {
          color: #333333;
        }
      }

      a {
        display: block;
        color: #b9b9b9;
        font-size: 17px;
        line-height: 1.7;
        text-decoration: none;
        cursor: pointer;
        transition: all .2s ease;

        &:hover {
          color: $primary;
        }
      }

      p {
        color: #b9b9b9;
        font-size: 16px;
        margin: 0;
        line-height: 1.2;
      }

      .icons {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .icon {
          margin-right: 20px;
          font-size: 22px;
          color: #333333;
          transition: all .2s ease;

          &:hover {
            color: $primary;
          }
        }
      }

      .contact-channels {
        a {
          margin-top: 20px;
          color: #333333;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .legal-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    

    a {
      text-decoration: none;
      margin: 0 40px;
      color: #b9b9b9;
      font-size: 16px;
      text-transform: uppercase;
      transition: all .2s ease;

      &:hover {
        color: $primary;
      }
    }
  }

  .logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .logo {
      margin: 0 75px;
      max-width: 150px;
    }
  }

  .copyright {
    text-align: center;
    font-size: 18px;
    letter-spacing: 0;
    color: #333333;
  }
}
