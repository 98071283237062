.section-head {
  position: relative;
  margin-bottom: 80px;

  h1 {
    text-align: center;
    font-size: 28px;
    margin: 100px 0 0 0;
  }

  img {
    display: block;

    &.mobile {
      max-width: none;
      width: 100%;
    }
  }

  .titles {
    position: absolute;
    top: 50%;
    right: 10%;
    transform: translateY(-50%);
    max-width: 400px;
    text-align: left;
    color: $primary;

    h1 {
      font-size: 55px;
      font-weight: 400;
      text-transform: uppercase;
      margin: 0 0 15px 0;
      text-align: left;
    }

    h2 {
      font-size: 25px;
      font-weight: 400;
      margin: 0;
    }
  }
}

.partial span,
.partial p {
  font-family: "Roboto Sans", sans-serif !important;
}

.section-no-results {
  margin-top: 50px;
  margin-bottom: 70px;

  img {
    display: inline-block;
  }

  p,
  h2 {
    display: block;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    font-size: 28px;
    color: $dark-grey;
  }

  h2 {
    color: $primary;
    margin-top: 15px;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.content-box {
  padding: 40px;
  border-radius: 2px;
  color: $medium-grey;

  &:not(.transparent) {
    /*border: solid 1px $light-grey;*/
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
  }

  .mat-flat-button,
  .mat-button {
    font-size: 16px;
  }

  .title {
    font-size: 23px;
    color: $dark-grey;
    text-align: center;
    margin-bottom: 20px;

    &.left {
      text-align: left;
    }
  }

  .subtitle {
    font-size: 20px;
    color: $primary;
  }

  .mat-table {
    thead {
      background-color: #f9f9f9;
    }

    th.mat-header-cell {
      font-size: 18px;
      color: $dark-grey;
    }

    td.mat-cell {
      font-size: 16px;
      color: $dark-grey;

      .bolder {
        font-weight: 600;
        color: $accent;
      }
    }
  }

  .mat-paginator-page-size-label {
    display: none;
  }

  .resume-box {
    display: block;
    margin-top: 20px;
    border: solid 1px #f8f8f8;

    &:not(.auto-height) {
      min-height: 180px;
    }

    &.lines-bottom {
      .line {
        border-bottom: solid 1px #f8f8f8;
        font-size: 20px;
      }
    }

    .line {
      padding: 10px;
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 16px;

      &.header {
        font-size: 20px;
        color: $dark-grey;
        background-color: #f8f8f8;
      }

      &.blur {
        filter: blur(4px);
        background-color: #f8f8f8;
      }

      span {
        padding: 0 3px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.accent {
          color: $accent;
        }

        &.primary {
          color: $primary;
        }
      }

      .greater {
        font-size: 24px;
      }
    }
  }
}

.participate-instructions {
  font-size: 17px;
  margin-bottom: 30px;
}

.challenge-tabs {
  .mat-ink-bar {
    display: none;
  }

  .mat-tab-label {
    min-width: 400px;
    margin: 1px 1px 0 1px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 3px 3px 0 0;

    &.mat-tab-label-active {
      background-color: #ffffff;
    }

    .mat-tab-label-content {
      font-size: 22px;
    }
  }

  & {
    .mat-tab-label {
      margin: 1px 10px 0 10px;
    }

    .mat-tab-label:first-child {
      margin-left: 1px;
    }

    .mat-tab-label:last-child {
      margin-right: 1px;
    }
  }
  .content-box {
    margin: 0 1px 1px 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
