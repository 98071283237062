.e-learning-contents-component {

  .section-no-results {
    margin-top: 100px;
  }

  .row {
    width: 100%;
    display: table;
    border-collapse: separate;
    border-spacing: 16px;

    .cell {
      display: table-cell;
      vertical-align: top;

      &.filters {
        width: 22%;

        .content-box {
          margin-bottom: 16px;
          padding: 15px;

          .title {
            font-size: 18px;
            color: $primary;
            text-align: left;
            margin: 20px 14px 0;
          }

          .main-title {
            font-size: 21px;
            text-align: center;
            margin: 10px 0;
          }
        }
      }
    }
  }

  .categories-tree,
  .categories-tree ul {
    list-style-type: none;
    padding-left: 15px;

    .mat-checkbox-layout .mat-checkbox-label {
      font-size: 14px;
    }
  }

  .mat-card {
    margin: 0 auto 10px;
    height: 352px;
    
    cursor: pointer;

    .mat-card-image {
      height: 155px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .mat-card-content {
      padding: 10px;
      text-align: left;

      .tags {
        div {
          padding: 10px 20px 7px;
          border: solid 1px $primary;
          font-size: 14px;
          color: $primary;
          display: inline-block;
          margin-right: 10px;
          border-radius: 2px;
          user-select: none;
        }
      }

      h3 {
        font-size: 18px;
        color: $light-grey;
        text-transform: uppercase;
      }

      p {
        color: $medium-grey;
        font-size: 16px;
      }
    }
  }
}
