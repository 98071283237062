.featured-posts-component {
  background-color: #ffffff;
  padding: 50px 0;

  swiper {
    height: 350px;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .mat-card {
        max-width: 300px;
        margin: 0 auto;
        box-shadow: none;
        cursor: pointer;

        .mat-card-image {
          height: 190px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        &:hover {
          box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        }

        .mat-card-content {
          text-align: left;

          h3 {
            font-size: 14px;
            color: $light-grey;
            text-transform: uppercase;
          }

          p {
            color: $medium-grey;
            font-size: 14px;
          }
        }
      }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 0;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 0;
    }

    .swiper-button-next:after,
    .swiper-container-rtl .swiper-button-prev:after,
    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after {
      font-size: 24px;
      color: $medium-grey;
    }
  }
}
