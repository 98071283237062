.showcase-component {  
    .pictures-area .pictures-lines {
      display: flex;
      width: 100%;
    }
  
    .pictures-area .pictures-lines .picture {
      width: 100%;
      min-height: 400px;
      margin: 2px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f9f9f9;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 3px;
    }
  
    .pictures-area .pictures-lines .picture.new-picture {
      transition: all 0.25s ease;
      cursor: pointer;
    }
    .pictures-area .pictures-lines .picture.new-picture:hover {
      background-color: #ddd;
    }
  
    .pictures-area .pictures-lines .picture.new-picture:hover .add-picture {
      color: #333;
    }
  
    .pictures-area .pictures-lines .picture.new-picture .mat-icon {
      height: 40px;
      width: 40px;
      font-size: 40px;
      line-height: 40px;
    }
  
    .pictures-area .pictures-lines .picture.new-picture .add-picture {
      text-align: center;
      color: #aaa;
    }
  
  
    .no-phases .mat-icon {
      margin-top: 50px;
      font-size: 80px;
      width: 100px;
      height: 100px;
    }
  
    .partial {
      margin-top: 20px;
    }
  
    .msg-help {
      margin: 10px 0 20px 0;
      text-align: right;
      color: $primary;
    }
  
  }
  