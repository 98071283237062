.e-learning-content-component {
  .page-inner {
    max-width: 1100px;
  }


  .section-head {
    .post-header {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    img {
      margin: 0 auto;
    }

    .post-content {
      font-family: "Roboto Sans", sans-serif !important;
    }
  }

  .back {
    button {
      padding-left: 0;
      padding-right: 8px;

      span {
        display: inline-block;
        padding-top: 3px;
      }
    }
  }

  .tags {

    div {
      padding: 10px 20px 7px;
      border: solid 1px $primary;
      font-size: 14px;
      color: $primary;
      display: inline-block;
      margin: 0 10px 0 0;
      border-radius: 2px;
      background-color: #ffffff;
      user-select: none;
    }
  }

  h1 {
    text-align: center;
    margin-top: 40px;
  }

  .videos,
  .files {
    .title {
      color: #333;
      font-size: 26px;
      padding: 40px 0 20px;
    }

    .mat-card {
      display: inline-block;
      margin: 5px;
      text-align: center;
      width: 15%;

      .mat-card-content {
        margin: 5px 0;
      }

      .file-preview {
        width: 100%;
        height: 120px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }

      .file-title {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
    }
  }
}
