.new-sale-component {

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: $primary;
  }

  .mat-step-icon-content {
    span {
      vertical-align: sub;
    }
  }

  .sale-form-wrapper {
    padding-bottom: 50px;
  }

  .products-area .product-line {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  .products-area .product-line .col-product,
  .products-area .product-line .col-quantity,
  .products-area .product-line .col-delete {
    padding-right: 2px;
    text-align: center;
  }

  .products-area .product-line .col-product {
    flex: 1 1 auto;
  }

  .products-area .product-line .col-points span {
    display: block;
    margin-top: 15px;
  }

  .products-area .product-line .col-delete {
    width: 50px;
  }

  .pictures-area .pictures-lines {
    display: flex;
    width: 100%;
  }

  .pictures-area .pictures-lines .picture {
    width: 33.33%;
    min-height: 100px;
    margin: 2px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
  }

  .pictures-area .pictures-lines .picture.new-picture {
    transition: all 0.25s ease;
    cursor: pointer;
  }

  .pictures-area .pictures-lines .picture.new-picture:hover {
    background-color: #ddd;
  }

  .pictures-area .pictures-lines .picture.new-picture:hover .add-picture {
    color: #333;
  }

  .pictures-area .pictures-lines .picture.new-picture .mat-icon {
    height: 40px;
    width: 40px;
    font-size: 40px;
    line-height: 40px;
  }

  .pictures-area .pictures-lines .picture.new-picture .add-picture {
    text-align: center;
    color: #aaa;
  }

  .unknown-product-error {
    text-align: left;
    margin-top: -10px;
    font-size: 10.5px;
    padding: 0 1em;
  }

  .total-product-points {
    text-align: left;
    margin-top: -19px;
    font-size: 12px;
    padding: 3px 1em;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 0 0 3px 3px;
  }

  .total-sale-points {
    text-align: left !important;
    padding-left: 1em;
    font-size: 13px;
  }

  .no-phases .mat-icon {
    margin-top: 50px;
    font-size: 80px;
    width: 100px;
    height: 100px;
  }

  .partial {
    margin-top: 20px;
  }

  .msg-help {
    margin: 10px 0 20px 0;
    text-align: right;
    color: $primary;
  }

  .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    .dot {
      width: 12px;
      height: 12px;
      margin: 0 5px;
      border-radius: 50%;
      background-color: #dcdcdc;
      border: solid 1px #b1b1b1;
      cursor: pointer;

      &.active {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }

  .phases-container {
    width: 100%;
    display: flex;

    .phase-container {
      height: 200px;
      width: 280px;
      border: 1px solid black;
      border-radius: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      margin: 0 20px 20px 0;
    }
  }

  .instructions {
    .mobile {
      display: none;
    }
  }

  .products-sheet {
    max-height: 600px;
    overflow: scroll;
  }

  .products-list {
    padding: 10px;
    max-height: 350px;
    overflow: auto;
    background-color: #f9f9f9;
    border-radius: 3px;

    .flex {
      align-items: baseline;
    }

    .mat-mdc-card {
      vertical-align: top;
      padding-top: 0;
      display: inline-block;
      margin: 5px;
      width: calc(100% / 4 - 10px);
      overflow: hidden;

      .product-picture {
        width: calc(100% + 32px);
        margin-left: -16px;
        height: 150px;
        display: block;
        border-radius: 2px 2px 0 0;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
      }

      .mat-card-title {
        font-size: 14px;
      }

      .mat-card-content {
        font-size: 12px;
        margin-bottom: 0;
        padding-top: 10px;
        height: 60px;
      }
    }
  }

  .highlight {
    font-weight: bold;
    text-decoration: underline;
  }
}

.mat-radio-group {
  margin: 0 0 20px 0;

  .mat-radio-button {
    margin: 5px;
  }
}

.sale-sheet-products-area {
  .product-line-sheet {
    font-size: 14px;
  }
}

.content-box {

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    flex-basis: 0;
  }

  .mat-expansion-panel-header-description {
    justify-content: space-between;
    align-items: center;
  }

  .mat-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }

  .sale-pictures .picture {
    width: 33.33%;
    min-height: 100px;
    margin: 2px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
  }

  h3 {
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 3px;
  }

  .table-cell {
    vertical-align: top;
    padding: 2px;
  }

  .sale-products {
    padding: 5px;

    .left {
      width: 80%;
    }

    .right {
      width: 20%;
    }
  }

  .totalize {
    border-top: solid 1px #000000;
    padding: 10px 5px;
    display: block;
    font-weight: 500;
  }

  .no-sales .mat-icon {
    margin-top: 50px;
    font-size: 80px;
    width: 100px;
    height: 100px;
  }

  .status {
    font-weight: 600;
    color: $primary;
  }

  .icon-expand {
    font-size: 20px;
    font-weight: 900;
  }

  .exp-date {
    margin: auto;
  }

  .points {
    margin-left: auto;
  }

  .subtitle {
    font-size: 12px !important;
    margin-bottom: 5px;
  }

  .mat-paginator {
    background-color: unset;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    color: #464646;
  }

  .mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label {
    font-weight: 700;
  }
}

@media (max-width: 600px) {

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    font-size: 11px;
  }

  .mat-expansion-panel-header-description {
    flex-grow: 3;
  }

  .mat-expansion-panel-header-title {
    flex-grow: 0.5 !important;
  }

  .mat-icon {
    overflow: unset !important;
  }

  .content-box {
    padding: 20px 0;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {

  .sale-detail {
    width: 100%;
  }

  .points-detail {
    width: 100%;
  }

}

@media (min-width: 1025px) {
  .sale-points {
    display: flex;
  }

  .sale-detail {
    width: 33%;
  }

  .points-detail {
    width: 64%;
  }
}