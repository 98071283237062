.test-statistics-component {
  .question-container {
    margin: 20px 0;
  }

  height: 80vh;

  table {
    width: 100%;
  }

  tr.detail-row {
    height: 0;
  }

  .element-row td {
    border-bottom-width: 0;
  }

  .element-detail {
    overflow: hidden;
    display: block;
  }

  .question {
    margin-top: 10px;
  }

  .correct {
    color: #4caf50;
  }

  .incorrect {
    color: #d9121c;
  }

  @media (min-width: 1025px) {
    .test-points {
      display: flex;
    }
  
    .test-detail {
      width: 33%;
    }
  
    .test-detail {
      width: 64%;
    }
  }
}
