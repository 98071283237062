.catalog-component {
  .gifts {
    .gifts-grid-component {
      .mat-grid-tile {
        overflow: unset;
      }
      .gift-picture {
        height: 150px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .mat-mdc-card-header {
        margin: auto;

        .mat-mdc-card-header-text {
          text-align: center;

          .mat-mdc-card-title {
            font-size: 16px;
          }

          .mat-mdc-card-subtitle {
            font-size: 14px;
          }
        }
      }

      .mdc-card__actions {
        margin: auto;
      }
    }
  }

  .row {
    width: 100%;
    display: table;
    border-collapse: separate;
    border-spacing: 16px;

    .cell {
      display: table-cell;
      vertical-align: top;

      &.filters {
        width: 22%;

        .content-box {
          margin-bottom: 16px;
          padding: 15px;

          .title {
            font-size: 21px;
          }

          .points {
            text-align: center;
            font-size: 40px;
            font-weight: 600;
          }

          .fit-to-points {
            margin: 20px 0;

            .mat-slide-toggle-content {
              white-space: normal;
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }

  .categories-tree,
  .categories-tree ul {
    list-style-type: none;
    padding-left: 15px;

    .mat-checkbox-layout .mat-checkbox-label {
      font-size: 13px;
    }
  }
}