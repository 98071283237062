.test-component {
  .test-container {
    .breadcrumbs {
      margin: 20px 0;
      text-align: right;
      font-size: 26px;

      span {
        display: inline-block;
        padding: 10px 10px 6px 10px;
        line-height: 1;
        border-radius: 5px;
        background-color: #f7f7f9;
      }
    }

    .question-container {
      .question-text {
        font-size: 20px;
        margin-bottom: 20px;

        .primary {
          font-size: 24px;
        }
      }

      .answers {
        .mat-radio-group {
          line-height: 1;

          .mat-radio-button {
            width: 100%;
            background-color: transparent;
            transition: all 0.2s ease;

            &.correct {
              background-color: #4caf50;
              color: #ffffff;
              border-radius: 3px;
              padding: 3px;
            }

            &.fail {
              opacity: 0.7;
            }
          }

          .mat-radio-label {
            white-space: normal;
          }
        }

        .result {
          margin: 20px 10px;
          font-size: 20px;
          line-height: 1;

          .mat-icon {
            margin-right: 20px;
          }

          .correct {
            color: #4caf50;
          }

          .incorrect {
            color: $primary;
          }
        }
      }

      .explanation {
        margin-top: 30px;
        padding: 20px;
        display: flex;
        background-color: #f7f7f9;
        border-radius: 3px;
        align-items: flex-start;

        .mat-icon {
          font-size: 30px;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }

        p {
          margin: 0 0 0 10px;
        }
      }
    }

    .actions {
      padding: 10px;
      margin-top: 20px;
      text-align: center;
    }
  }

  .finish-container {
    width: 100%;
    margin: 50px 0;
    text-align: center;

    img {
      max-width: 220px;
    }

    .has-legal-bases {
      width: 50%;
      margin: 0 auto 20px auto;

      .mat-checkbox-layout {
        vertical-align: top;
        text-align: left;
      }

      .text {
        white-space: normal;
        a {
          color: $primary;
        }
      }
    }
  }
}
