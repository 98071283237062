.points-component {

    .content-box {
        padding: 50px;
    }

    .mat-sidenav-content {
        min-height: 50vh;
    }

    .points-boxes {
        display: flex;

        @media (max-width:640px) {
            flex-direction: column;
        }

        justify-content: space-around;

        .points-available {
            border-right: 1px solid rgba(0, 0, 0, 0.25);
            margin: 20px 0;

            @media (max-width:640px) {
                border: 0;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                padding-bottom: 20px;
            }

            .available-points-label {
                font-size: 48px;
            }

            .available-points {
                color: $primary;
                font-size: 64px;
                font-weight: 900;
                margin-bottom: 20px;
            }

            .available-points-warning {
                font-size: 18px;
            }

            .available-points-warning.italic {
                font-style: italic;
            }
        }

        .points-sections {
            margin-left: 20px;

            @media (max-width:640px) {
                width: 100%;
                margin-left: 0;
            }

            .current-points-label {
                font-size: 32px;
                margin-left: 30px;

                @media (max-width:640px) {
                    margin-left: 0;
                }
            }

            .current-points {
                color: $primary;
                font-size: 36px;
                font-weight: 900;
                margin-left: 30px;

                @media (max-width:640px) {
                    margin-left: 0;
                }
            }

            .points-box {
                @media (max-width:640px) {
                    margin-left: 0;
                    padding: 10px 0;
                }
            }

            .points-box:nth-child(1),
            .points-box:nth-child(2) {
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                margin-bottom: 5px;
            }
        }
    }

    .filters-container {
        width: 300px;
        border-left: 1px solid rgba(0, 0, 0, 0.12);
    }

    .filters-container .form-container {
        padding: 20px;
    }

    .filters-container .form-container .mat-form-field {
        width: 100%;
        margin-bottom: 20px;
    }

    .filters-container .actions {
        text-align: right;
    }

    .filters-container .actions button {
        margin-left: 10px;
    }

    .title {
        text-align: left;
        color: $primary;
    }

    .warning {
        color: #c3201e !important;
    }
}