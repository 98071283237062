/* Base */
@import 'scss/vars';
@import 'scss/normalize';
@import 'scss/theme';
@import 'scss/base';

/* Components */
@import 'scss/partials/common';
@import 'scss/partials/tutorial';
@import 'scss/partials/login.component';
@import 'scss/partials/dashboard.component';
@import 'scss/partials/user-area.component';
@import 'scss/partials/footer.component';
@import 'scss/partials/profile.component';
@import 'scss/partials/account.component';
@import 'scss/partials/challenges-list.component';
@import 'scss/partials/challenge.component';
@import 'scss/partials/new-sale.component';
@import 'scss/partials/showcase.component';
@import 'scss/partials/instructions';
@import 'scss/partials/test.component';
@import 'scss/partials/test-statistics.component';
@import 'scss/partials/video.component';
@import 'scss/partials/catalog.component';
@import 'scss/partials/gifts-grid.component';
@import 'scss/partials/featured-posts.component';
@import 'scss/partials/featured-contents.component';
@import 'scss/partials/e-learning-contents.component';
@import 'scss/partials/e-learning-content.component';
@import 'scss/partials/posts.component';
@import 'scss/partials/post.component';
@import 'scss/partials/program-selection.component';
@import 'scss/partials/faqs.component';
@import 'scss/partials/points.component';


/*Responsive*/
@import 'scss/partials/responsive';