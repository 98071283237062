:root{
    /* Main colors */
    --primary: #0f386b;
    --accent: #fff;
    --yellow-color-1: #ffd200;
    --yellow-color-2: #ffea00;
    --yellow-color-3: #efdc04;
    --yellow-color-4: #f0bc00;
    --red-color-1: #ff0000;
    --red-color-2: #eb0303;
    --red-color-3: #c70303;
    --gray-color-1: #f5f5f5;
    --gray-color-2: #e0e0e0;
    --gray-color-3: #bdbdbd;
    --green-color-1: #00ff00;
    --green-color-2: #00d100;
    --green-color-3: #009200;
    --green-color-4: #4caf50;
    --blue-color-1:  #007d89;
    --blue-color-2:  #00a0b0;
    --blue-color-3:  #00c0d1;
    --gray-color-1: #f9f9f9;
}



$primary: #9a044d;
$accent: #535b7c;
$dark-grey: #333333;
$medium-grey: #525252;
$light-grey: #b9b9b9;
$green: #008000;
$red: #d70000;
$blue: #1976d2;
$done: #008000;