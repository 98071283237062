.challenges-list-component {
  .content-box {
    padding: 20px;

    .title {
      margin-top: 30px;
      font-size: 28px;
      color: #000000;
    }

    .challenge {
      display: table;
      width: 100%;
      margin-bottom: 70px;

      .picture {
        display: table-cell;
        position: relative;
        z-index: 2;
        width: 350px;
        height: 245px;
        background-color: #f1f1f1;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 2px;
      }

      .content {
        display: table-cell;
        position: relative;
        z-index: 1;
        padding: 20px 0;

        .content-wrapper {
          height: 205px;
          padding: 30px;
          background-color: #ffffff;

          .new-label {
            position: absolute;
            top: 20px;
            right: 50px;
            width: 0;
            height: 0;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            border-top: 50px solid $primary;

            span {
              color: #ffffff;
              font-size: 14px;
              position: absolute;
              left: 50%;
              top: -40px;
              transform: translateX(-50%);
              user-select: none;
            }
          }

          .completed-label {
            position: absolute;
            top: 20px;
            right: 50px;
            width: 0;
            height: 0;
            border-left: 60px solid transparent;
            border-right: 60px solid transparent;
            border-top: 60px solid $primary;

            span {
              color: #ffffff;
              font-size: 14px;
              position: absolute;
              left: 50%;
              top: -52px;
              transform: translateX(-50%);
              user-select: none;
            }
          }

          * {
            text-align: left;
          }

          .title {
            color: $primary;
            text-transform: uppercase;
            font-size: 14px;
            margin: 0 0 10px 0;
          }

          .short-description {
            font-size: 18px;
            max-width: 85%;
            margin-right: auto;

            * {
              padding: 0;
              margin: 0;
              line-height: 1.25;
            }

            small {
              display: block;
              font-size: 14px;
              margin-top: 10px;
            }
          }

          .see-more {
            display: block;
            text-align: right;
            position: absolute;
            right: 10px;
            bottom: 30px;

            a {
              font-size: 18px;
              color: $primary;
            }

            .inactive {
              color: #000000;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
            }
          }
        }

        .dates {
          color: $primary;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }

      &.inactive {
        opacity: 0.7;
      }

      &.hansaplast {
        .content {
          .content-wrapper {
            .new-label,
            .completed-label {
              border-top-color: $accent;
            }

            .see-more {
              a {
                color: $accent;
              }
            }
          }

          .dates,
          .title {
            color: $accent;
          }
        }
      }
    }
  }

  .access-required-dialog-component {
    text-align: center;
    margin-bottom: 30px;

    .container {
      display: inline-block;
      margin: 0 auto;
      text-align: left;

      .promo-code {
        width: 400px;
      }

      .mat-form-field {
        position: relative;

        .mat-form-field-suffix {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .challenges-list-component {
    .access-required-dialog-component {
      .container {
        width: 100%;

        .form-container {
          width: 100%;
        }
        
        .promo-code {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
