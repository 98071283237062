.featured-contents-component {
  background-color: #f7f7f9;
  padding: 20px 0;

  .content-box {
    .title {
      margin-bottom: 30px;
    }
  }

  .page-inner {
    max-width: 1100px;
    position: relative;
  }

  swiper {
    height: 470px;

    .swiper-slide {
      display: flex;
      justify-content: center;
      text-align: center;

      .mat-card {
        margin: 0 auto 10px;

        cursor: pointer;

        .mat-card-image {
          height: 230px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .mat-card-content {
          padding: 10px;
          text-align: left;

          h3 {
            font-size: 18px;
            color: $light-grey;
            text-transform: uppercase;
          }

          p {
            color: $medium-grey;
            font-size: 16px;
          }
        }
      }
    }

    .tags {
      div {
        padding: 10px 20px 7px;
        border: solid 1px $primary;
        font-size: 14px;
        color: $primary;
        display: inline-block;
        margin-right: 10px;
        border-radius: 2px;
      }
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: -20px;
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: -20px;
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after,
  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    font-size: 24px;
    color: $medium-grey;
  }
}

@media only screen and (max-width: 600px) {
  .featured-contents-component {
    swiper {
      .swiper-slide {
        .mat-card {
          .mat-card-image {
            height: 130px;
            background-size: contain;
            background-position: top;
          }
        }
      }
    }
  }
}
