/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";

.primary {
  color: $primary;
}

.bold {
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  padding: 0 2px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.25;
  font-size: 15px;
  outline: none;
  background-color: #f7f7f9;
}

* {
  outline: none;
}

.hide {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

img:not(.mat-card-image) {
  max-width: 100%;
}

.slider-content .drag-scroll-content {
  overflow: hidden !important;
}

.table {
  display: table;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.flex-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.flex,
.flex-col,
.flex-row {
  display: flex;
}

.flex-row {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-center {
  align-items: center;
}

.flex-spacer {
  flex: 1 1 auto;
}

.full-height-vh {
  height: 100vh;
}

.full-height-vh {
  height: 100vh;
}

.size8of8 {
  width: 100%;
}

.size7of8 {
  width: 87.5%;
}

.size6of8 {
  width: 75%;
}

.size5of8 {
  width: 62.5%;
}

.size4of8 {
  width: 50%;
}

.size3of8 {
  width: 37.5%;
}

.size2of8 {
  width: 25%;
}

.size1of8 {
  width: 12.5%;
}

.padding--lvl0 {
  padding: 0;
}

.padding--lvl1 {
  padding: 10px;
}

.padding--lvl2 {
  padding: 20px;
}

.padding--lvl3 {
  padding: 30px;
}

.padding--lvl4 {
  padding: 40px;
}

.padding-bottom--lvl0 {
  padding-bottom: 0;
}

.padding-bottom--lvl1 {
  padding-bottom: 10px;
}

.padding-bottom--lvl2 {
  padding-bottom: 20px;
}

.padding-bottom--lvl3 {
  padding-bottom: 30px;
}

.padding-bottom--lvl4 {
  padding-bottom: 40px;
}

.padding-left--lvl1 {
  padding-left: 10px;
}

.m0 {
  margin: 0 !important;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.m30 {
  margin: 130px;
}

.mauto {
  margin: auto !important;
}

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-top: 50px;
}

.mlauto {
  margin-left: auto;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.pt0 {
  padding-top: 0 !important;
}

.hidden {
  display: none;
}

.done {
  color: $done;
}

.small-text {
  font-size: 12px;
  color: #999999;
}

.pointer {
  cursor: pointer;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}

.fixed-bottom button {
  height: 46px;
  border-radius: 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mat-toolbar {
  &.dashboard-toolbar {
    border-top: 3px solid #a70532;
    background-color: #fff;
    color: #525252;

    .logo {
      position: absolute;
      left: 50%;
      height: 40px;
      transform: translateX(-50%);
    }
  }
}

.mat-icon {
  vertical-align: middle;
}

.mat-card.only-picture {
  padding-bottom: 0;
}

.mat-card.only-picture img {
  display: block;
  line-height: 1;
  border-radius: 3px;
}

.submit-wrapper {
  background-color: #f1f1f1;
}

.mat-drawer-container.admin-page {
  position: absolute;
  top: 68px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.mat-expanded {
  border-bottom: solid 1px #f1f1f1;
}

.mat-expanded .mat-expansion-panel-header,
.mat-expanded .mat-expansion-panel-header.mat-expanded:hover {
  background: #f5f5f5;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  align-items: center;
}

.mat-drawer-container.admin-page .mat-drawer-content {
  padding: 20px;
  box-sizing: border-box;
}

.mat-drawer-container.admin-page .filters-container {
  width: 300px;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-drawer-container.admin-page .filters-container .form-container {
  padding: 20px;
}

.mat-drawer-container.admin-page .filters-container .form-container .mat-form-field {
  width: 100%;
  margin-bottom: 20px;
}

.mat-drawer-container.admin-page .filters-container .actions {
  text-align: right;
}

.mat-drawer-container.admin-page .filters-container .actions button {
  margin-left: 10px;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.top-actions {
  margin-bottom: 20px;
}

.mat-toolbar {
  z-index: 5;
}

.mat-toolbar.list-toolbar {
  background-color: #fff;
}

.mat-toolbar.list-toolbar .selected-msg {
  font-size: 14px;
  font-weight: 400;
}

.mat-toolbar.list-toolbar .mat-icon {
  font-size: 24px;
  color: #888;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.mat-row {
  cursor: pointer;
  transition: all 0.25s ease;
}

.mat-row:hover {
  background-color: #f5f5f5;
}

.mat-progress-bar.thin {
  height: 2px;
}

.list-filters-drawer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.list-container {
  position: relative;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg-notice {
  background-color: #ebebeb;
  color: $accent;
  padding: 10px;
}

.msg-notice .mat-icon,
.msg-help .mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
  color: $accent;
  margin-right: 5px;
}

.form-content .fr-toolbar {
  border-color: #1976d2;
}

/* Forms */

.login-control .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.8);
}

.mat-bottom-sheet-container .loading-shade {
  bottom: 0;
}

.shopping-cart-form-container .mat-bottom-sheet-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  max-height: 100vh !important;
  width: 60%;
  min-width: 0% !important;
  z-index: 0 !important;
  background-color: #fafafa;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-bottom-sheet-container.filters-container {
  width: 30%;
}

.mat-bottom-sheet-container .form-toolbar {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.mat-bottom-sheet-container .form-toolbar .close {
  transform: rotate(0deg);
  transition: all 0.25s ease;
}

.mat-bottom-sheet-container .form-toolbar .close:hover {
  transform: rotate(90deg);
}

.mat-bottom-sheet-container .form-toolbar .title {
  margin-left: 10px;
}

.mat-bottom-sheet-container .form-content {
  max-width: 70%;
  margin: 76px auto 0;
}

.mat-bottom-sheet-container .form-content .mat-form-field {
  margin-bottom: 20px;
}

.button-row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  padding: 10px;
}

.mat-tab-label.mat-tab-label-active {
  color: #a70532;
  opacity: 1;
}

.mat-tab-label-content .mat-icon {
  font-size: 18px;
  line-height: 24px;
  margin-right: 5px;
}

.form-content .mat-tab-body.mat-tab-body-active {
  overflow-y: hidden;
}

.form-content .mat-tab-body-content {
  padding: 5px;
  overflow: hidden;
}

.form-content.import {
  max-width: 300px;
}

.form-content .upload-area {
  width: 100%;
  height: 100px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border: solid 1px #f1f1f1;
  border-radius: 3px;
  position: relative;
}

.form-content .import-data .upload-area {
  height: 150px;
}

.form-content .import-data .upload-area .mat-icon {
  font-size: 70px;
  height: 70px;
  width: 70px;
}

.form-content .upload-area label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(249, 249, 249, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease;
}

.form-content .upload-area label.hide {
  display: none;
}

.form-content .upload-area .picture-preview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-content .upload-area .picture-preview img {
  max-height: 100%;
  cursor: pointer;
}

tr.mat-footer-row {
  font-weight: 500;
  background-color: #f9f9f9;
}

.front-page {
  padding-bottom: 50px;
}

.fixed-toolbar {
  position: fixed;
  top: 0;
  z-index: 5;
}

.fixed-page {
  padding: 76px 20px 50px 20px;
  z-index: 1;

  &.register-complete {
    max-width: initial;
  }
}

.register-complete-dialog {
  .image {
    text-align: center;

    img {
      margin-top: 30px;
      max-width: 150px;
    }
  }

  .title,
  .content {
    text-align: center;
  }

  h2 {
    margin-bottom: 10px;
  }

  .actions {
    justify-content: center !important;

    button {
      background-color: #a70532;
      color: #fff;
    }
  }
}

/* Forms */

.pb50 {
  padding-bottom: 50px;
}

.legal-page {
  margin-top: 84px !important;
  padding-top: 40px;

  * {
    font-family: "Roboto", sans-serif !important;
  }
}

.dialog-fit-content .mat-dialog-container {
  padding: 0 0 10px 0;
}

.commercial-communications-dialog-container .mat-dialog-container {
  padding: 0 !important;
  overflow: hidden;
  max-width: 500px;
}

.access-granted-dialog {
  .mat-dialog-container {
    max-width: 430px;
  }
}

.mat-bottom-sheet-container {
  &.cookies-modal {
    padding: 30px;
  }
}

.cookies-config-modal {
  width: 80%;
  max-width: 900px !important;

  .mat-dialog-container {
    padding: 0;
  }
}

.events-modal-panel {
  .mat-dialog-container {
    position: relative;
    padding: 10px;
    max-width: 420px;
  }
}

.event-wrapper .text .hash {
  color: #a70532;
}

.front-page,
.fixed-page {
  margin: 0 auto;

  .page-inner {
    margin: 0 auto;
    padding: 0 20px;
  }
}

.mat-mdc-paginator-page-size-label {
  display: none;
}

.center {
  text-align: center;
}

@media (min-width: 1025px) {
  .mat-dialog-container {
    min-width: 350px;
  }

  .fixed-page {
    padding-top: 84px;
  }

  .front-page,
  .fixed-page {
    max-width: 1920px;

    .page-inner {
      max-width: 1300px;
    }
  }

  .front-page.catalog-grid {
    max-width: 1920px;
  }
}

@media (max-width: 1025px) {

  .shopping-cart-form-container .mat-bottom-sheet-container,
  .mat-bottom-sheet-container {
    width: 100%;
    padding: 0;
  }

  .legal-page {
    margin-top: 50px !important;
    height: 100%;
  }

  .cookies-config-modal {
    max-width: 100vw;
    width: 100%;
  }
}

@media (max-width: 600px) {

  .login-card-container .mat-form-field-label,
  .login-card-container .mat-input-element {
    color: #ffffff;
  }

  .login-card-container .mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: #ffffff;
  }

  .mat-toolbar {
    h1 {
      display: none;
    }
  }

  .mat-bottom-sheet-container {
    &.cookies-modal {
      padding: 20px;

      .cookies-content {
        display: block;

        .flex.flex-center {
          justify-content: flex-end;
        }
      }
    }
  }

  .user-area-component {
    .slide-control {
      display: none;
    }
  }

  video {
    max-width: 100% !important;
    height: auto !important;
  }
}