.dashboard-container {
  .dashboard-toolbar {
    .top {
      border-bottom: solid 1px #5252;
      position: relative;
      
      .user-menu {
        padding: 5px;

        .profile-image {
          width: 31px;
          height: 31px;
          border-radius: 50%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;

          &.empty {
            background-image: url("/assets/img/no-results/user.svg");
          }
        }

        .info {
          .name,
          .points {
            text-align: left;
            line-height: 1.1;
            padding-left: 10px;
            font-size: 14px;
            font-weight: bold;
            color: #000;
          }

          .name {
            color: $primary;
          }
        }
      }

      .notifications-indicator {
        .mat-icon {
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 20px;
          color: $light-grey;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .bottom {
      height: 40px;
      text-align: center;
      justify-content: center;

      a {
        color: #525252;
        text-decoration: none;
        font-size: 16px;
        line-height: 1;
        margin: 0 20px;
        transition: all 0.2s ease;
        font-weight: 100;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

button.mat-menu-item {
  height: 40px;
  line-height: 40px;
  color: #525252;
  font-size: 15px;
  border-bottom: solid 1px #5252;

  .mat-icon {
    font-size: 22px;
    height: 22px;
    width: 20px;
    line-height: 1;
  }
}
