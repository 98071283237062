.gifts-grid-component {
  .mat-grid-tile {
    .mat-figure {
      align-items: flex-start;
      padding: 0 3px;
    }
  }

  .mat-grid-list {
    margin-bottom: 70px;
  }

  mat-card {
    padding: 0 5px 20px;
    overflow: hidden;
    height: 100%;

    &.no-stock {
      background-color: #eaeaea;
    }

    .gift-msg-overlay {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      bottom: 0;
      z-index: 2;

      &.catalog-closed {
        background-color: rgba(0, 0, 0, 0.28);

        .content {
          margin: 10px 25px;
          padding: 10px 30px;
          background-color: #ffffff;
          text-align: center;
          border-radius: 3px;

          p {
            margin-bottom: 0;
          }
        }
      }

      .line {
        height: 30px;
        line-height: 30px;
        color: #ffffff;
        text-align: center;
        background-color: $primary;
        font-size: 16px;
      }
    }

    .mat-card-header {
      text-align: center;
      margin: 10px 0 0 0;

      .mat-card-header-text {
        width: 100%;
      }

      .mat-card-title,
      .mat-card-subtitle {
        margin-bottom: 10px;
      }

      .mat-card-title {
        font-size: 16px;
        min-height: 60px;
      }

      .mat-card-subtitle {
        font-size: 15px;
        margin-top: 0;
      }
    }

    .mat-card-actions {
      margin: 0;
      padding: 0 10px;
      text-align: center;

      .mat-flat-button {
        width: 60%;
      }
    }

    .gift-picture {
      width: calc(100% + 32px);
      margin-left: -16px;
      height: 120px;
      display: block;
      border-radius: 2px 2px 0 0;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
    }

    .title h2 {
      margin: 0;
      text-align: center;
    }
  }
}

.gift-selection-component {
  .container {
    display: flex;
    align-items: flex-start;

    .gift-picture {
      width: 400px;
      height: 400px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .gift-description {
      //width: 400px;
      padding: 0 20px;

      h1 {
        margin: 0 0 10px 0;
        font-size: 20px;
      }

      h3 {
        margin: 0;
        color: $primary;
      }

      .description {
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 10px 0;
      }
    }
  }
}


@media (max-width: 1024px) {
  .gift-selection-component {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .gift-picture {
        width: 200px;
        height: 200px;
      }

      .gift-description {
        width: 100%;
        padding: 0 10px;

        .description {
          max-height: initial;
          overflow: auto;
          margin: 10px 0;
        }
      }
    }
  }
}