.introjs-overlay {
  position: absolute;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.32);
  opacity: 0;
  transition: all 0.3s ease-out;
}

.introjs-fixParent {
  z-index: auto !important;
  opacity: 1 !important;
}

.introjs-showElement {
  z-index: 9999999 !important;
}

.introjs-relativePosition {
  position: relative;
}

.introjs-helperLayer {
  position: absolute;
  z-index: 9999998;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #777;
  border: 3px solid rgba(211, 214, 209, 1);
  border-radius: 0;
  box-shadow: 0 5px 8px -3px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.introjs-helperNumberLayer {
  position: absolute;
  top: -5px;
  left: initial;
  right: -30px;
  z-index: 9999999999 !important;
  padding: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: bold;
  color: white; 
  background: $primary;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border: 3px solid $primary;
  border-radius: 10px 10px 10px 0; 
}

.introjs-arrow {
  border: 5px solid white;
  content: "";
  position: absolute;
}
.introjs-arrow.top {
  top: -10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #ecf0f1;
  border-left-color: transparent;
}
.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #ecf0f1;
}
.introjs-arrow.bottom {
  bottom: -10px;
  border-top-color: #ecf0f1;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-top-color: transparent;
  border-right-color: #ecf0f1;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-tooltip {
  position: absolute;
  padding: 20px;
  background-color: #ffffff;
  min-width: 300px;
  max-width: 400px;
  border-radius: 5px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  transition: opacity 0.1s ease-out;
}

.introjs-tooltiptext {
  margin-left: -10px;
  margin-right: -10px;
  color: #333;
  padding: 5px 10px;
}

.introjs-tooltipbuttons {
  margin-top: 20px;
  text-align: center;
}

/*
   Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
   Changed by Afshin Mehrabani
  */
.introjs-button {
  box-sizing: border-box;
  position: relative;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  transition: all 0.15s ease;
  text-shadow: none;
  box-shadow: none;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
}

.introjs-button:hover,
.introjs-button:focus,
.introjs-button:active,
introjs-skipbutton:active,
introjs-donebutton:active {
  color: #fff;
  background: $primary;
  text-decoration: none;
  box-shadow: none;
}

/* overrides extra padding on button elements in Firefox */
.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.introjs-skipbutton {
  margin-right: 5px;
}

.introjs-skipbutton:hover,
.introjs-skipbutton:active,
.introjs-skipbutton:focus {
  background: #c3201e;
  box-shadow: none;
  color: #fff;
}

.introjs-prevbutton {
  -webkit-border-radius: 0.2em 0 0 0.2em;
  -moz-border-radius: 0.2em 0 0 0.2em;
  border-radius: 0.2em 0 0 0.2em;
  border-right: none;
}

.introjs-nextbutton {
  -webkit-border-radius: 0 0.2em 0.2em 0;
  -moz-border-radius: 0 0.2em 0.2em 0;
  border-radius: 0 0.2em 0.2em 0;
}

.introjs-disabled,
.introjs-disabled:hover,
.introjs-disabled:focus {
  color: #c2c2c2 !important;
  border-color: #d4d4d4;
  cursor: default;
  box-shadow: none;
  background-color: #e6e6e6;
  background-image: none;
  text-decoration: none;
}

.introjs-bullets {
  text-align: center;
}
.introjs-bullets ul {
  clear: both;
  margin: 15px auto 0;
  padding: 0;
  display: inline-block;
}
.introjs-bullets ul li {
  list-style: none;
  float: left;
  margin: 0 2px;
}
.introjs-bullets ul li a {
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  text-decoration: none;
}
.introjs-bullets ul li a:hover {
  background: #999;
}
.introjs-bullets ul li a.active {
  background: #999;
}

.introjs-progress {
  background-color: #fafafa;
}
.introjs-progressbar {
  background-color: #2980b9;
}

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}
