@media only screen and (max-width: 1440px) {
  .footer-component {
    .links-and-info {
      .box {
        width: 33%;

        h3 {
          font-size: 18px;
        }

        a,
        p {
          font-size: 15px;
        }
      }
    }

    .legal-menu {
      a {
        font-size: 15px;
      }
    }

    .logos {
      .logo {
        text-align: center;
      }
    }

    .copyright {
      font-size: 16px;
    }
  }

  .account-component {
    .row {
      .content-box {
        p {
          font-size: 16px;
        }
      }
    }
  }

  .content-box {
    padding: 30px;

    .title {
      font-size: 21px;
    }

    .subtitle {
      font-size: 18px;
    }

    .mat-button,
    .mat-flat-button {
      font-size: inherit;
    }

    .resume-box {
      &:not(.auto-height) {
        min-height: 140px;
      }

      .line {
        font-size: 15px;
      }

      .line.header {
        font-size: 18px;
      }
    }

    .mat-table {
      th.mat-header-cell {
        font-size: 16px;
      }

      td.mat-cell {
        font-size: 15px;
      }
    }
  }

  .section-head {
    h1 {
      font-size: 26px;
      margin-top: 60px;
    }
  }

  .section-no-results {
    img {
      margin-bottom: 30px;
      max-width: 280px;
    }

    p,
    h2 {
      font-size: 24px;
    }
  }

  .challenges-list-component {
    .content-box {
      .title {
        font-size: 26px;
      }

      .challenge {
        .content {
          .content-wrapper {
            .short-description {
              font-size: 16px;
            }

            .see-more {
              a {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  .section-head {
    .titles {
      right: 8%;

      h1 {
        font-size: 48px;
      }

      h2 {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .gifts-grid-component {
    .mat-card {
      .gift-msg-overlay {
        &.catalog-closed {
          .content {
            font-size: 14px;
            padding: 15px 10px;
            margin: 12px 15px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .footer-component {
    .links-and-info {
      .box {
        width: 33%;

        h3 {
          font-size: 16px;
        }

        a,
        p {
          font-size: 14px;
        }
      }
    }

    .legal-menu {
      a {
        font-size: 14px;
      }
    }

    .copyright {
      font-size: 15px;
    }
  }

  .account-component {
    .row {
      .content-box {
        p {
          font-size: 15px;
        }
      }
    }
  }

  .content-box {
    padding: 30px;

    .title {
      font-size: 19px;
    }

    .subtitle {
      font-size: 16px;
    }

    .mat-button,
    .mat-flat-button {
      font-size: inherit;
    }

    .resume-box {
      .line {
        font-size: 14px;
      }

      .line.header {
        font-size: 16px;
      }
    }

    .mat-table {
      th.mat-header-cell {
        font-size: 15px;
      }

      td.mat-cell {
        font-size: 14px;
      }
    }
  }

  .section-head {
    h1 {
      font-size: 24px;
      margin-top: 40px;
    }
  }

  .section-no-results {
    img {
      margin-bottom: 30px;
      max-width: 250px;
    }

    p,
    h2 {
      font-size: 22px;
    }
  }

  .challenges-list-component {
    .content-box {
      .title {
        font-size: 24px;
      }

      .challenge {
        .content {
          .content-wrapper {
            .short-description {
              font-size: 15px;
            }

            .see-more {
              a {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .catalog-component,
  .e-learning-contents-component {
    .row {
      display: block;

      .cell {
        display: block;

        &.filters {
          width: 100%;

          .available-points {
            display: none;
          }

          .mat-checkbox {
            display: block;
          }
        }
      }
    }
  }

  .section-head {
    .titles {
      right: 5%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .front-content {
    .front-page {
      padding-bottom: 20px;
    }
  }

  .footer-component {
    .links-and-info {
      padding: 0 20px 20px 20px;

      .box {
        width: 33%;
        text-align: center;

        .icons {
          justify-content: center;
        }
      }
    }

    .legal-menu {
      a {
        width: 25%;
        margin: 0 15px;
      }
    }

    .logos {
      .logo {
        margin: 0 25px;
      }
    }
  }

  .challenges-list-component {
    .content-box {
      padding: 20px 0;

      .title {
        margin-top: 20px;
      }

      .challenge {
        display: block;
        margin-bottom: 30px;

        .picture {
          display: block;
          width: 100%;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background-size: contain;
        }

        .content {
          display: block;
          padding: 0;
          z-index: 3;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          .dates {
            right: 6px;
            bottom: 3px;
          }

          .content-wrapper {
            .see-more {
              position: initial;
              right: initial;
              bottom: initial;
              text-align: center;
              margin: 20px 0;
            }

            .new-label {
              top: 0;
              right: 20px;
            }

            .completed-label {
              top: 0;
              right: 0px;
            }
          }
        }
      }
    }
  }

  .profile-component {
    .profile-data {
      .inner-content-box {
        flex-direction: column;

        &>div {
          width: 100%;
          margin: 0;
        }
      }
    }

    .profile-content {
      flex-direction: column;

      .container {
        width: auto;

        &.right {
          margin: 16px 0 0 0;
        }
      }
    }
  }

  .account-component {
    .row {
      display: block;

      .content-box {
        width: initial;
        display: block;
        margin-bottom: 20px;

        &.points-box {
          width: initial;
        }
      }
    }
  }

  .challenge-tabs {
    .mat-tab-label {
      min-width: initial;

      .mat-tab-label-content {
        font-size: initial;
      }
    }
  }

  .content-box {
    .partial {
      width: 100%;
    }
  }

  .new-sale-component {
    .phases-container {
      display: block;
    }
  }

  .exp-date {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .featured-contents-component {
    swiper {
      .swiper-slide {
        .mat-card {
          .mat-card-content {
            h3 {
              font-size: 16px;
            }

            p {
              font-size: 14px;
            }

          }
        }
      }
    }
  }

  .section-head {
    .titles {
      max-width: 200px;

      h1 {
        font-size: 24px;
        margin-bottom: 10px;
      }

      h2 {
        font-size: 14px;
      }
    }
  }

  .mat-toolbar {
    &.dashboard-toolbar {
      .logo {
        height: 35px;
      }

      .top {
        .notifications-indicator {
          .mat-icon {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 24px;
          }
        }
      }
    }
  }

  .rrss-component {
    display: none;
  }

  .dashboard-container {
    .dashboard-toolbar {
      .bottom {
        a {
          font-size: 16px;
        }
      }
    }
  }

  .mobile-menu {
    .menu-button {
      transition: 300ms ease-in-out;
      transform: rotate(0deg);
    }

    .menu-button.rotated {
      transform: rotate(180deg);
    }

    .submenu {
      background-color: #f9f9f9;
      transition: 300ms ease-in-out;
      overflow: hidden;
      height: 0;
    }

    .submenu.expanded {
      display: block;
      height: 96px;
    }

    .submenu.three.expanded {
      display: block;
      height: 144px;
    }

    .submenu.four.expanded {
      display: block;
      height: 192px;
    }

    .expand-icon {
      position: absolute;
      right: 10px;
    }

    .mat-list-item {
      font-size: 14px;
    }

    .mat-list-item.open {
      background-color: #eee;
    }

    .mat-list-item .material-icons {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    .mat-list-item .item-icon {
      margin-right: 5px;
    }

    .submenu .mat-list-item {
      padding-left: 10px;
    }

    .mat-nav-list {
      padding-top: 0;
    }

    .list-item-container,
    .mat-nav-list .mat-list-item.border {
      border-width: 1px 0 0 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.12);
    }

    .list-item-container:last-child {
      border-width: 1px 0;
    }

    .account-info {
      background-color: #f9f9f9;
      padding: 10px 0;
      text-align: center;

      .profile-image {
        width: 100px;
        height: 100px;
        margin: 10px auto;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &.empty {
          background-image: url("/assets/img/no-results/user.svg");
        }
      }

      .name,
      .points {
        font-weight: bold;
        color: #000;
      }

      .name {
        color: $primary;
      }
    }

    .account-picture {
      display: block;
      margin: 0 auto;
    }

    .account-info .available-points,
    .account-info .user-email {
      font-weight: 500;
      text-align: center;
      margin-bottom: 5px;
    }

    .account-info .available-points {
      font-size: 16px;
      color: #1976d2;
    }
  }

  .footer-component {
    padding-top: 20px;

    .links-and-info {
      .box {
        width: 100%;

        h3 {
          font-size: 20px;
        }

        a,
        p {
          font-size: 16px;
        }
      }
    }

    .legal-menu {
      a {
        width: initial;
        margin: 10px;
        display: block;
        text-align: center;
      }
    }
  }

  .challenges-list-component {
    .content-box {
      .challenge {
        .picture {
          background-size: cover;
          background-position: center top;
          height: 200px;
        }

        .content {
          .dates {
            right: 0;
            left: 0;
            text-align: center;
          }

          .content-wrapper {
            height: auto;
            padding: 30px 20px;

            .see-more {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .account-component {
    .row {
      .content-box.points-box {
        .current-points {
          font-size: 80px;
        }
      }
    }
  }

  .profile-component {
    .profile-content {
      .container.left {
        .content-box {
          .inner-content-box {
            display: block;

            .profile-image {
              margin: 0 auto;
            }
          }
        }
      }
    }

    .content-extra-info {

      .half-width {
        width: 100%;

        .l-img {
          margin-left: unset;
          margin: auto;
        }

        .l-info {
          margin: 0 auto 0 20px;

          .l-subtitle {
            font-size: 14px !important;
          }
        }
      }

      .ml20 {
        margin-left: 0;
      }
    }
  }

  .content-box {
    padding: 20px;

    &.resume-box:not(.auto-height) {
      height: auto;
    }

    h3 {
      text-align: center;
    }
  }

  .mat-table {
    .mat-cell {
      padding: 10px;
    }
  }

  .challenge-tabs {
    .mat-tab-label {
      margin: 1px 0 0 0;

      .mat-tab-label-content {
        font-size: 14px;
      }
    }
  }

  .challenge-component {
    .mat-step-header {
      padding-left: 0 !important;
    }

    .mat-vertical-content-container {
      margin-left: 0 !important;
    }

    .mat-form-field {
      font-size: 14px !important;
    }

    .mat-vertical-content {
      padding-right: 0 !important;
    }

    .mat-stepper-vertical-line::before {
      left: 11px;
    }
  }

  .new-sale-component {
    .products-list {
      max-height: 800px;

      .mat-mdc-card {
        padding-top: 0;
        display: block;
        margin: 0px 0px 10px 0;
        width: initial;

        .product-picture {
          height: 80px;
        }

        .mat-card-content {
          height: auto;
          margin-bottom: initial;
        }
      }
    }
  }

  .video-component {
    .video-container {
      width: 100%;

      .video-control {
        .mat-icon {
          font-size: 30px;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }

  .e-learning-content-component {

    .postheader {
      align-items: center;
      flex-direction: column;

      .request_training,
      .request_purchase {
        margin-top: 10px;
      }
    }

    .files {
      .flex {
        flex-direction: column;
      }

      .mat-card {
        width: auto;

        .mat-card-content {
          h3 {
            font-size: 15px;
          }

        }
      }
    }
  }

  .e-learning-contents-component {
    .mat-card {
      .mat-card-image {
        height: 116px;
      }

      .mat-card-content {
        h3 {
          font-size: 15px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .new-sale-component {
    .instructions {
      .desktop {
        display: none;
      }

      .mobile {
        display: block;
      }
    }
  }
}