.user-area-component {
  .banners-container {
    .mat-card {
      box-shadow: none;
    }

    .dots {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      .dot {
        width: 12px;
        height: 12px;
        margin: 0 5px;
        border-radius: 50%;
        background-color: #dcdcdc;
        border: solid 1px #b1b1b1;
        cursor: pointer;

        &.active {
          background-color: #a70532;
          border-color: #a70532;
        }
      }
    }
  }

  .mat-card-title span {
    vertical-align: middle;
  }

  .banners-card {
    padding: 0;
    position: relative;

    img {
      vertical-align: middle;
    }

    .slide-control {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &:before {
        font-size: 1.5em;
        color: $medium-grey;
        display: inline-block;
        vertical-align: sub;
      }

      &.left {
        left: 50px;
      }

      &.right {
        right: 50px;
      }

      &.left:hover,
      &.right:hover {
        &:before {
          color: $primary;
        }
      }
    }

    .mat-mini-fab.mat-primary {
      transition: background-color 0.3s;
      background-color: rgba(255, 255, 255, 0.3);
      border: 3px solid #ffffff;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      line-height: 60px;

      &:hover {
        background-color: #ffffff;
      }

      .mat-icon {
        color: $medium-grey;
        height: 60px;
        width: 60px;
        line-height: 60px;
        font-size: 60px;
      }
    }
  }

  .banner-wrapper,
  .banner-wrapper a {
    display: inline-block;
  }

  .how-to-participate {
    .title {
      font-size: 26px;
      color: #000000;
    }

    margin-top: 20px !important;

    .mobile {
      display: none;
    }
  }

  .incentivized-products {
    display: flex;
    margin: 15px auto;

    a {
      margin: auto;
      height: 48px;
      line-height: 48px;
      border-radius: 24px;
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .user-area-component {
    .how-to-participate {
      .mobile {
        display: block;
      }

      .desktop {
        display: none;
      }
    }
  }
}