.account-component {
  .row {
    width: 100%;
    margin: 0;
    display: table;
    border-collapse: separate;
    border-spacing: 16px;

    .content-box {
      width: 50%;
      display: table-cell;
      
      &.points-box {
        width: 60%;

        .current-points {
            font-size: 147px;
            font-weight: 600;
            text-align: center;
        }

        .current-points-label {
            font-size: 28px;
            text-align: center;
        }
      }

      .user-data {
        margin-bottom: 20px;

        p {
          margin: 7px 0;
        }
      }

      .profile-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 20px;

        &.empty {
          background-image: url("/assets/img/no-results/user.svg");
        }
      }

      p {
        font-size: 18px;
        color: $medium-grey;
        margin: 10px 0;
      }
    }
  }
}
