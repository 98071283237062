.post-component {
  .section-head {
    .post-header {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    img {
      margin: 0 auto;
    }

    .post-content {
      font-family: "Roboto Sans", sans-serif !important;
    }
  }

  .breadcrumbs {
    margin-bottom: 40px;

    a {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  h1 {
    text-align: center;
    margin-top: 50px;
  }
}
