.login-component {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  color: #223341;
  background: url("/assets/img/home/bg-singuladerm.jpg") #eeeeee no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
  height: 100vh;

  .access {
    position: absolute;
    left: -99999px;
  }

  .left {
    position: absolute;
    right: 70%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 400px;
    margin-left: 50px;

    .login-form {
      display: flex;
      text-align: right;

      .fields {
        .mdc-text-field--filled {
          border-top-left-radius: 10px !important;
          border-top-right-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
          border-bottom-left-radius: 10px !important;
          border: 2px solid $primary;
          height: 50px;
        }

        .password .mat-mdc-form-field-infix {
          display: contents;
        }
      }

      .auth-failed {
        height: 0px;
      }

      h3 {
        font-size: 18px;
        margin: 0 0 5px;
      }

      .login-button,
      .register-button {
        min-width: 120px;
        height: 50px;
        line-height: 40px;
        border-radius: 10px;

        .mat-progress-spinner {
          margin: 0 auto;
        }
      }

      .login-button-container {
        position: relative;
        bottom: 68px;
        left: 125px;
        height: 0px;
      }

      .login-actions {
        width: 100%;

        &>div {
          flex: 1 1 auto;
          text-align: left;
        }

        .remember-button {
          font-size: 14px;
          text-align: left;
          color: #525252;
          transition: all 0.2s ease;
          text-decoration: none;
          margin-left: 15px;
          margin-top: 5px;

          &:hover {
            color: $primary;
          }
        }

        .register-button {
          margin-left: 22px;

          a {
            text-decoration: underline;
          }
        }
      }

      .register-actions {
        width: 100%;
        margin-top: 20px;
        display: none;

        &>div {
          margin-bottom: 5px;
        }
      }
    }
  }

  .title-web {
    max-width: 500px;
    margin-bottom: 0;

    img {
      width: 100%;
      height: auto;
      max-width: 450px;
      margin: 0 auto;
      display: block;
    }

    .welcome,
    .logo-subtitle {
      display: block;
      text-align: left;
      font-size: 14px;
    }

    .logo-subtitle {
      text-align: right;
      margin-bottom: 40px;
    }
  }

  .box {
    background-color: #f7f7f9;
    width: 80%;
    max-width: 500px;
    padding: 10%;
  }

  .box .text {
    text-align: center;
    line-height: 150%;
    font-size: 27px;
  }

  .box .text .bold {
    font-weight: bold;
  }

  .info {
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;

    a {
      padding: 0 25px;
      text-decoration: none;
      color: #494949;
      transition: all 0.25s ease;

      &:hover {
        color: $primary;
      }
    }

    .logo {
      display: block;
      width: 120px;
      margin-left: 25px;
    }
  }
}

.remember-password-component {
  .page-inner {
    max-width: 800px;
  }

  .remember-form .mat-icon,
  .remember-complete .mat-icon {
    font-size: 100px;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}

@media only screen and (max-width: 900px) {
  .login-component {
    .maintenance {
      box-shadow: none;
    }

    .login-form {
      display: block !important;

      .login-actions {
        &>div {
          display: none;
        }
      }

      .login-button,
      .register-button {
        display: block;
        min-width: initial;
        width: 100%;
        margin: 10px 0 0;
      }

      .login-button-container {
        display: none;
      }

      .register-actions {
        text-align: center;
        display: block !important;
      }
    }

    .left {
      background-color: #ffffff9a;
      width: 80%;
      left: 50%;
      top: 50%;
      padding: 8% 5%;
      transform: translate(-50%, -55%);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.18);
    }
  }
}

@media only screen and (max-width: 600px) {
  .login-component {
    .left {
      margin-left: 0;

      .login-form {}
    }

    .title-web {
      margin-top: 0;

      img {
        max-width: 250px;
      }

      .welcome {
        margin-bottom: 0;
      }
    }

    .footer {
      display: block;
      padding: 10px 10px 0 10px;

      a:first-child {
        display: none;
      }

      a {
        display: inline-block;
        width: 50%;
        padding: 5px 0;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .login-component {
    .left {
      padding: 5%;
    }

    .title-web {
      img {
        max-width: 200px;
      }
    }
  }
}