.faqs-component {

    h2 {
        font-size: 1.25rem;
        margin: 0 0 10px;
        color: $accent;
    }

    .categories {
        width: 30%;

        .mat-card {
            padding: 20px 0;
            border-left: solid 3px $primary;

            .mat-list {
                padding-top: 0;

                a {
                    text-align: left;
                    font-size: 1rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .contents {
        width: 70%;
        padding: 0 0 0 30px;

        .mobile-selector {
            display: none;
        }

        .content {
            margin: 0 0 40px 0;

            .mat-expanded {
                .mat-expansion-panel-header {
                    .mat-expansion-panel-header-title {
                        color: $primary;
                        font-weight: 600;
                    }
                }
            }

            .faq-content {
                padding-top: 7.5px;
            }


            h2 {
                margin: 0 0 20px;
            }
        }
    }
}


@media only screen and (max-width: 768px) {
    .faqs-component {
        .categories {
            display: none;
        }
    
        .contents {
            width: 100%;
            padding: 0;

            .mobile-selector {
                display: block;
                margin-bottom: 20px;

                button {
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
    
}