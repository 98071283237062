.program-selection-dialog {
    .mat-dialog-container {
        background-color: #f9f9f9;
        max-width: 800px;
        max-height: 90vh;
    }
}

.program-selection-component {
    .mat-mdc-card-title {
        font-size: 22px;
        text-align: center;
    }

    .mat-mdc-card {
        position: relative;
        cursor: pointer;
        margin: 10px;
        width: 200px;
        height: 200px;
        transition: all 0.2s ease;

        &:hover {
            background-color: #f1f1f1;
        }

        .current {
            position: absolute;
            right: 0;
            top: 0;
            background-color: $primary;
            color: #fff;
            padding: 5px 10px;
            border-radius: 0 0 3px 3px;
        }

        .mat-card-title {
            font-size: 18px;
            text-align: center;
        }

        img {
            width: 140px;
            margin: 40px auto 40px;
            display: block;
        }
    }

    .policy-container {
        max-height: 500px;
        overflow: auto;
        border: solid 1px #ccc;
        border-radius: 3px;
        padding: 20px 10px;
        background-color: #f5f5f5;
    }
}

@media (max-width: 600px) {
    .program-selection-dialog {
        max-width: 100vw !important;
        height: 100%;

        .program-selection-component {
            .mat-mdc-card-title {
                font-size: 16px;
                line-height: 1.2;
            }

            .programs-list.flex {
                flex-direction: column;
            }

            .mat-mdc-card {
                width: inherit;
            }

            .mat-dialog-content {
                max-height: initial;
            }

            .policy-container {
                max-height: 60vh;
                font-size: 14px;
            }
        }
    }
}