.challenge-component{
  h2 {
    margin-bottom: unset;
  }

  h1, h2 {
    color: #525252;
  }

  .statistics {
    padding: 0 50px;
    margin-bottom: 20px;
  
    div {
      display: grid;
      border: solid $primary 3px;
      border-radius: 15px;
      padding: 20px;
  
      .title {
        font-size: 18px;
        font-weight: 700;
      }
      .points {
        color: $primary;
        font-size: 56px;
        font-weight: 700;
        letter-spacing: -3px;
      }
      .subtitle {
        color: var(--mat-expansion-header-description-color);
        font-size: 16px;
      }
    }
  }
}







@media (max-width: 600px) {
  .statistics {
    display: block;

    div {
      width: auto;
    }

    div:nth-child(2) {
      margin-left: unset;
      margin-top: 20px;
    }
  }
}