.posts-component {
  .mat-grid-tile .mat-figure {
    align-items: flex-start;
  }

/*   .mat-card {
    height: 89%;
    // min-width: 15.625vw;
    // max-width: 15.625vw;
    width: 300px;
    margin: 0 auto;
    box-shadow: none;
    cursor: pointer;
    
    .mat-card-image {
      height: 190px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    .mat-card-content {
      text-align: left;

      h3 {
        font-size: 14px;
        color: $light-grey;
        text-transform: uppercase;
        margin-top: 0;
      }

      h4 {
        margin: 0;
        color: $primary;
      }

      p {
        color: $medium-grey;
        font-size: 14px;
      }
    }
  } */
  .mat-card {
    margin: 0 auto 10px;
    height: 352px;
    cursor: pointer;

    .mat-card-image {
      height: 155px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .mat-card-content {
      padding: 10px;
      text-align: left;

      .tags {
        div {
          padding: 10px 20px 7px;
          border: solid 1px $primary;
          font-size: 14px;
          color: $primary;
          display: inline-block;
          margin-right: 10px;
          border-radius: 2px;
          user-select: none;
        }
      }

      h3 {
        font-size: 18px;
        color: $light-grey;
        text-transform: uppercase;
      }

      p {
        color: $medium-grey;
        font-size: 16px;
      }
    }
  }
}
